import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import classNames from './kind.module.scss';
import messages from './kind.messages';

const Kind = ({ isComponent }) => (
  <Tooltip
    placement="top"
    title={
      <FormattedMessage {...(isComponent ? messages.COMPONENT : messages.PRODUCT)} />
    }
  >
    <div className={classNames.kindContainer}>
      <FormattedMessage {...(isComponent ? messages.COMP : messages.PROD)} />
    </div>
  </Tooltip>
);

Kind.displayName = 'Kind';

Kind.propTypes = {
  isComponent: PropTypes.bool.isRequired,
};

export default Kind;
